'use strict';

import { getPhone, getOperator } from './main/helpers';

// переменные для обработки клика по интпут
//const inpFlagElem = document.querySelector(".js-input-group");
const iconArrowElem = document.querySelector('.js-icon-down');
const inputGroupElem = document.querySelector('.js-input-group');
const menuContainerElem = document.querySelector('.js-menu-container');

const menuItemAll = document.querySelectorAll('.MenuItem');
const inputNumberElem = document.querySelector('.js-input-number');
const inputCountryElem = document.querySelector('.js-form-control');

const logoImage = document.querySelector('#logo');

console.log(logoImage)

switch (getOperator()) {
  case "mts":
    logoImage.className = "logo_mts";
    break;
    case "tele":
    logoImage.className = "logo_tele";
    break;
    case "beeline":
    logoImage.className = "logo_beeline";
    break;
    case "ks":
    logoImage.className = "logo_ks";
    break;
    case "mts_ukr":
    logoImage.className = "logo_mts_ukr";
    break;
    case "life":
    logoImage.className = "logo_life";
    break;
    case "phenix":
      logoImage.className = "logo_phenix";
    break;
}


inputGroupElem.addEventListener('click', () => {
  if (iconArrowElem.classList.contains('open')) {
    iconArrowElem.classList.remove('open');
  } else {
    iconArrowElem.classList.add('open');
  }

  if (inputGroupElem.classList.contains('focus')) {
    inputGroupElem.classList.remove('focus');
  } else {
    inputGroupElem.classList.add('focus');
  }

  if (menuContainerElem.classList.contains('open')) {
    menuContainerElem.classList.remove('open');
  } else {
    menuContainerElem.classList.add('open');
  }

  if (menuContainerElem.classList.contains('shown')) {
    menuContainerElem.classList.remove('shown');
  } else {
    menuContainerElem.classList.add('shown');
  }
});

// Прячет меню при нажатии в любом месте документа
document.addEventListener('click', e => {

  const click = e.composedPath().includes(inputGroupElem);
  if (!click) {
    iconArrowElem.classList.remove('open');
    inputGroupElem.classList.remove('focus');
    menuContainerElem.classList.remove('open');
    menuContainerElem.classList.remove('shown');
  }
});

//Перевіряємо на якому з списків був клік, та відпрацьовуємо цей елемент
menuItemAll.forEach(item => {
  item.addEventListener('click', e => {
    //e.target.classList.add('active');

    // Працюємо з полем input номер телефону
    inputNumberElem.firstElementChild.value =
      item.lastElementChild.textContent + ' ';
    inputNumberElem.classList.add('touched');
    inputNumberElem.firstElementChild.focus();

    // Працюємо з полем input Країна
    inputCountryElem.value = item.children[1].textContent;
    //inputGroupElem.children[1].textContent = "";
    inputGroupElem.classList.add('touched');
    //inputCountryElem.classList.add('focus');
    //console.log(inputNumberElem.elementChild[1]);
  });
});

// подсчет символов в input
// let msg
// inputNumberElem.addEventListener('focusout', event=>{
//     let length = inputNumberElem.value.length;
//     if (length > 12) msg = 'Количество символов не должно превышать 12!';
//     else if (length < 11) msg = 'Количество символов не должно быть меньше 11!';
//     alert(length);
// })


inputNumberElem.firstElementChild.value = getPhone();

if (getPhone() == null) {
  // Виставляємо по Дефолту значення країни та телефона
  // Номер телефону
  inputNumberElem.firstElementChild.value = '+7 ';
  inputNumberElem.classList.add('touched');
  inputNumberElem.firstElementChild.focus();

  // Працюємо з полем input Країна
  inputCountryElem.value = 'Russian Federation';
  inputGroupElem.classList.add('touched');
} else {
    const n = getPhone()
    let patternPhone = ""

    if (n[0] === '7'){
        if (n.length < 11){
            patternPhone = "+7 "
        } else {
            patternPhone = "+7 "+ n[1]+n[2]+n[3]+" "+n[4]+n[5]+n[6]+" "+n[7]+n[8]+n[9]+n[10];
        }
        
        inputNumberElem.firstElementChild.value = patternPhone;
        inputNumberElem.classList.add('touched');
        inputNumberElem.firstElementChild.focus();
      
        // Працюємо з полем input Країна
        inputCountryElem.value = 'Russian Federation';
        inputGroupElem.classList.add('touched');
    }

    if (n[0] === '3' && n[1] === '8' &&n[2] === '0'){
        if(n.length < 12){
            patternPhone = "+380 "
        }else{
            patternPhone = "+380 "+ n[3]+n[4]+" "+n[5]+n[6]+n[7]+" "+n[8]+n[9]+" "+n[10]+n[11];
        }
        
        inputNumberElem.firstElementChild.value = patternPhone;
        inputNumberElem.classList.add('touched');
        inputNumberElem.firstElementChild.focus();
      
        // Працюємо з полем input Країна
        inputCountryElem.value = 'Ukraine';
        inputGroupElem.classList.add('touched');
    }
    if (n[0] === '3' && n[1] === '7' &&n[2] === '5'){
        if(n.length < 12){
            patternPhone = "+380 "
        }else{
            patternPhone = "+380 "+ n[3]+n[4]+" "+n[5]+n[6]+n[7]+" "+n[8]+n[9]+n[10]+n[11];
        }
        
        inputNumberElem.firstElementChild.value = patternPhone;
        inputNumberElem.classList.add('touched');
        inputNumberElem.firstElementChild.focus();
      
        // Працюємо з полем input Країна
        inputCountryElem.value = 'Ukraine';
        inputGroupElem.classList.add('touched');
    }

}

